import axios from "axios"

export class ApiResponse {
    constructor(ok, data, message = "") {
        this.ok = ok
        this.data = data
        this.message = message
    }
}

export default class ApiHelper {
    constructor() {
        // console.log('Creating API helper instance')
        const token = localStorage.getItem('order_room_token')
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            params: typeof token !== 'undefined' ? {
                token
            } : {}
        })
    }



    async verifyIp() {
        try {
            const response = await this.axios.get('/order-room/find-page')
            return new ApiResponse(true, "OK", response.data.message)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }

    }


    async getDashboard() {
        try {
            const response = await this.axios.get('/order-room/counts')
            return new ApiResponse(true, response.data.data, response.data.message)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }

    }

    async attemptLogin(username, password) {
        try {
            const response = await this.axios.post('/login', {
                username,
                password,
                request_permission: true
            })
            return new ApiResponse(true, response.data, 'Auth successful')
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }

    }

    async searchOrder(query, type = 'mobile') {
        try {
            const response = await this.axios.get('/order-room/search', {
                params: {
                    query,
                    type
                }
            })
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async fetchCancelled() {
        try {
            const response = await this.axios.get('/order-room/cancelled')
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async fetchPending(page, limit = 10, type = '') {
        try {
            const params = {
                page: page ?? 1,
                limit
            }
            if (type !== '') {
                params['type'] = type
            }
            const response = await this.axios.get('/order-room/pending', {
                params: params
            })
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async fetchUnshipped(page, limit = 10) {
        try {
            const response = await this.axios.get('/order-room/unshipped', {
                params: {
                    page: page ?? 1,
                    limit
                }
            })
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async fetchSent(page, limit = 10, type = 'in_transit') {
        try {
            const response = await this.axios.get('/order-room/sent', {
                params: {
                    page: page ?? 1,
                    limit,
                    type
                }
            })
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async acceptOrder(orderId, crmId) {
        try {
            const response = await this.axios.get('/order-room/update/accept', {
                params: {
                    orderId,
                    crmId
                }
            })
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async updateOrderPaymentStatus(orderId, status, mode) {
        try {
            const body = {
                orderId,
                status,
                mode
            }

            const response = await this.axios.post('/order-room/update-payment-status', body)
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async updatePendingOrderStatus(orderId, crmId, status, message, orderType = '') {
        try {
            const body = {
                orderId,
                status
            }
            if (status === 'verify') {
                body['crmId'] = parseInt(crmId)
            } else {
                body['message'] = message
            }
            if (orderType !== '') {
                body['type'] = orderType
            }

            const response = await this.axios.post('/order-room/pending', body)
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async updateUnshippedOrder(orderId, courierPartner, trackingId, status, message, dispatchDate) {
        try {
            const body = {
                orderId,
                status
            }

            if (status === 'dispatch') {
                body['courierPartner'] = courierPartner
                body['trackingId'] = trackingId
                body['dispatchDate'] = dispatchDate
            } else {
                body['message'] = message
            }

            const response = await this.axios.post('/order-room/unshipped', body)
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async updateSentOrder(orderId, status, deliveredDate, returnMessage) {
        try {
            const body = {
                orderId,
                status
            }

            if (status === 'delivered') {
                body['deliveredAt'] = deliveredDate
            } else {
                body['message'] = returnMessage
            }

            const response = await this.axios.post('/order-room/sent', body)
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }

    async listCourierPartners() {
        try {
            const response = await this.axios.get('/courier-partner')
            return new ApiResponse(true, response.data.data)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return new ApiResponse(false, null, error.response?.data?.message ?? error.message)
            }
            return new ApiResponse(false, null, 'Something went wrong')
        }
    }
}