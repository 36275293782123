import { AndroidOutlined, Apple, CancelOutlined, Close, ErrorOutlineOutlined, HelpOutlineOutlined, HomeOutlined, KeyboardReturnOutlined, LanguageOutlined, LocalShippingOutlined, LocationOnOutlined, MailOutlined, PendingOutlined, QueryBuilderOutlined, VerifiedOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Button, Card, Chip, Dialog, DialogContent, Divider, IconButton, LinearProgress, Slide, Tab, Tabs, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import TimeAgo from 'react-timeago';
import { ApiHelper } from '../Helpers';



// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//         marginLeft: theme.spacing(1),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('sm')]: {
//             width: '17ch',
//             '&:focus': {
//                 width: '25ch',
//             },
//         },
//     },
// }));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})



export default function Layout(props) {
    const [searchDialogState, setSearchDialogState] = React.useState({
        query: '',
        open: false,
        isSearching: false,
        result: [],
        error: '',
        executedOnce: false
    })
    // const [actionDrawerState, setActionDrawerState] = React.useState({
    //     open: false,
    // })
    const handleUserLogout = () => {
        localStorage.removeItem('order_room_token')
        localStorage.removeItem('order_room_tab')
        window.location.reload()
    }
    const [selectedOrder, setSelectedOrder] = React.useState("")
    // const [actionProgress, setActionProgress] = React.useState(false)

    const searchOrder = async (type) => {
        const query = searchDialogState.query.trim()
        if (query.length > 5) {
            const apiHelper = new ApiHelper()
            setSearchDialogState(old => ({ ...old, result: [], isSearching: true, error: '', executedOnce: true }))
            const response = await apiHelper.searchOrder(query, type)
            if (response.ok) {
                console.log('Response Data:P ', response.data)
                setSearchDialogState(old => ({ ...old, result: response.data.orders, isSearching: false }))
            } else {
                setSearchDialogState(old => ({ ...old, result: response.data, error: response.message, isSearching: false }))
            }
        } else {
            setSearchDialogState(old => ({ ...old, error: 'Too short search query!' }))
        }
    }

    const searchColumns = [
        {
            field: 'created_at', headerName: 'Order Date', type: 'date', width: 180, renderCell: (cell) => {
                return <div>
                    <Typography variant='subtitle2' fontWeight={'bold'}>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
                    <Typography variant='caption'><TimeAgo date={cell.value} /> at {moment(cell.value).format('hh:mm A')}</Typography>
                </div>
            }
        },
        {
            field: 'orderid', headerName: 'Order Detail', width: 150, renderCell: (cell) => {
                return <Tooltip title={`${cell.value} - ${cell.row.full_name}`} >
                    <div>
                        <Typography noWrap variant="body2" fontWeight={'bold'}>
                            {cell.value}
                        </Typography>
                        <Typography variant='subtitle2' noWrap>
                            {cell.row.full_name}
                        </Typography>
                    </div>
                </Tooltip >
            }
        },
        {
            field: 'crmid', headerName: 'CRM Id', width: 130, renderCell: (cell) => {
                return <Tooltip title={cell.value} >
                    <Typography variant='subtitle2' noWrap>
                        {cell.value ?? "N/A"}
                    </Typography>
                </Tooltip >
            }
        },
        {
            field: 'payment_status', headerName: 'Payment Status', width: 170, renderCell: (cell) => {
                if (cell.value === "Pending") {
                    return <Chip color='warning' label='Pending' />
                } else if (cell.value === "Success") {
                    return <Chip color='success' label='Success' />
                } else if (cell.value === "Failed") {
                    return <Chip color='error' label='Failed' />
                } else {
                    return <Chip color='default' label='Unknown' />
                }
            }
        },
        {
            field: 'order_source_zone', headerName: 'Order Source', width: 170, renderCell: (cell) => {
                console.log(cell.value)
                if (cell.value === "Website") {
                    return <Chip color='info' label='Website' icon={<LanguageOutlined />} />
                } else if (cell.value === "Android") {
                    return <Chip color='success' label='Android' icon={<AndroidOutlined />} />
                } else if (cell.value === "iOS") {
                    return <Chip color='secondary' label='iOS' icon={<Apple />} />
                } else {
                    return <Chip color='default' label='Unknown' icon={<HelpOutlineOutlined />} />
                }
            }
        },
        {
            field: 'product_name', headerName: 'Product Name', width: 200, renderCell: (cell) => {
                return <Tooltip title={cell.row.productList[0].short_name}>
                    <div>
                        <Typography noWrap variant="body2">
                            {cell.row.productList[0].short_name}
                        </Typography>
                        {
                            cell.row.product_count > 1 ? <Typography noWrap variant="caption">
                                with {cell.row.product_count - 1} other
                            </Typography> : null
                        }
                    </div>
                </Tooltip>
            }
        },
        {
            field: 'total_price', headerName: 'Order Amount', width: 170, type: 'number', renderCell: (cell) => {
                return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
                    <Chip className='text-capitalize mt-1' color={cell.row.payment_mode === 'online' ? 'success' : 'secondary'} label={`${value} - ${cell.row.payment_mode}`} />
                </div>} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
            }
        },
        {
            field: 'phone', headerName: 'Contact', width: 140, renderCell: (cell) => {
                return <Tooltip title={cell.value}>
                    <Typography noWrap variant="body2">
                        {cell.value}
                    </Typography>
                </Tooltip>
            }
        },
        {
            field: 'state', headerName: 'Destination', width: 170, renderCell: (cell) => {
                return <Tooltip title={`${cell.value} - ${cell.row.pincode}`} >
                    <div>
                        <Typography noWrap variant="body2" fontWeight={'bold'}>
                            {cell.value}
                        </Typography>
                        <Typography variant='subtitle2' noWrap>
                            <MailOutlined fontSize='small' /> {cell.row.pincode}
                        </Typography>
                    </div>
                </Tooltip >
            }
        },
    ]

    const closeSearchInterface = () => {
        setSearchDialogState(old => ({ ...old, open: false, error: '', result: [], query: '', executedOnce: false }))
    }


    // const openActionDrawer = () => {
    //     // JUST TO GO THROUGH CI-CD
    //     console.log(actionDrawerState)
    //     console.log(setActionProgress)
    //     // JUST TO GO THROUGH CI-CD


    //     setActionDrawerState({ open: true })
    // }


    const orderDetailsColumn = [
        {
            field: 'product_image', headerName: 'Image', width: 150, filterable: false, sortable: false, renderCell: (cell) => {
                return <img src={cell.value} alt={cell.row.product_name} className='img-fluid img-thumbnail' style={{
                    maxHeight: 60,
                    maxWidth: 60
                }} />
            }
        },
        { field: 'product_name', headerName: 'Product Name', flex: 1 },
        {
            field: 'payment_status', headerName: 'Payment Status', width: 170, renderCell: (_) => {
                if (selectedOrder.row.payment_status === "Pending") {
                    return <Chip color='warning' label='Pending' />
                } else if (selectedOrder.row.payment_status === "Success") {
                    return <Chip color='success' label='Success' />
                } else if (selectedOrder.row.payment_status === "Failed") {
                    return <Chip color='error' label='Failed' />
                } else {
                    return <Chip color='default' label='Unknown' />
                }
            }
        },
        {
            field: 'item_qty', headerName: 'Item Quantity', width: 180, renderCell: (cell) => {
                return <Chip label={cell.value} color="info" />
            }
        },
        {
            field: 'paid_amount', headerName: 'Paid Amount', width: 180, renderCell: (cell) => {
                return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
                    <Chip className='text-capitalize mt-1' color={selectedOrder.row.payment_mode === 'online' ? 'success' : 'secondary'} label={`${value} - ${selectedOrder.row.payment_mode}`} />
                </div>} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
            }
        },
    ]


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Avatar alt="AR Ayurveda" src="/logo512.png" style={{ backgroundColor: 'white' }} className="me-3" />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Order Room
                        <Typography variant='subtitle2' style={{ color: '#ffffffb5' }}>
                            Manage Ayurvedic Health Care Orders
                        </Typography>
                    </Typography>
                    <Button className='ms-2' startIcon={<SearchIcon />} onClick={_ => setSearchDialogState(old => ({ ...old, open: true }))} variant="contained" color='info'>
                        Search
                    </Button>
                    <Button className='ms-2' onClick={handleUserLogout} variant="contained" color="error">
                        Logout
                    </Button>
                </Toolbar>
                <Divider />
                <Tabs
                    value={props.currentTab}
                    onChange={(_, tab) => props.setCurrentTab(tab)}
                    indicatorColor="secondary"
                    textColor="inherit"
                    className='px-2'
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_dashboard') ? <Tab label="Dashboard" icon={<HomeOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_pending') ? <Tab label="New" icon={<PendingOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_unshipped') ? <Tab label="Accepted" icon={<VerifiedOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_hold') ? <Tab label="Hold" icon={<QueryBuilderOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_cancelled') ? <Tab label="Cancelled" icon={<CancelOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_sent') ? <Tab label="Dispatched" icon={<LocalShippingOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_delivered') ? <Tab label="Delivered" icon={<LocationOnOutlined />} iconPosition="start" /> : null
                    }
                    {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_returned') ? <Tab label="Returned" icon={<KeyboardReturnOutlined />} iconPosition="start" /> : null
                    }
                </Tabs>
            </AppBar>
            {
                props.children
            }
            {
                selectedOrder !== "" ? <Dialog
                    fullScreen
                    open={selectedOrder !== ""}
                    // onClose={}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={_ => setSelectedOrder("")}
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Order Details
                                <Typography variant="subtitle2" color="#fffc" >Order Id: {selectedOrder.row.orderid} | CRM Id: {selectedOrder.row.crmid ?? 'N/A'}</Typography>
                            </Typography>
                            {
                                typeof selectedOrder.row.tracking_link !== 'undefined' ? <Button href={selectedOrder.row.tracking_link} target="_blank" color="inherit" variant='outlined'>
                                    Track Now
                                </Button> : null
                            }
                        </Toolbar>
                    </AppBar>
                    <div className="container mt-5">
                        <Typography variant='h5'>Order Status: {selectedOrder.row.current_status ?? 'Unknown'}</Typography>
                        <div className="row">
                            <div className="col-12 col-md-6 p-2">
                                <Card variant="outlined" className='p-2'>
                                    <Typography variant='h5'>Ship To</Typography>
                                    <Typography>{selectedOrder.row.full_name} - (<a className='text-decoration-none' href={`tel:${selectedOrder.row.phone}`}>{selectedOrder.row.phone}</a>)</Typography>
                                    <Typography>
                                        {
                                            `${selectedOrder.row?.address_line_1 ?? ''}, ${selectedOrder?.row.address_line_2 ?? ""}, ${selectedOrder.row?.city ?? ""}, ${selectedOrder.row?.district ?? ""}, ${selectedOrder.row?.state ?? ""} - ${selectedOrder.row?.pincode ?? ""}`
                                        }
                                    </Typography>
                                </Card>
                            </div>
                            <div className="col-12 col-md-6 p-2">
                                <Card variant="outlined" className='p-2'>
                                    <Typography variant='h5'>Order Information</Typography>
                                    Order Amount: <NumberFormat displayType='text' value={selectedOrder.row.total_price} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
                                    <Typography>Total Items: {selectedOrder.row.productList.length}</Typography>
                                    <Typography>Ordered at: {moment(selectedOrder.row.created_at).format('DD/MM/YYYY hh:mm A')}</Typography>
                                </Card>
                            </div>
                            <div className="col-12 col-md-6 p-2">
                                <Card variant="outlined" className='p-2'>
                                    <Typography variant='h5'>Tracking Information</Typography>
                                    <Typography>Courier Provider: {selectedOrder.row.courier_provider ?? 'N/A'}</Typography>
                                    <Typography>Tracking Id: {selectedOrder.row.tracking_id ?? 'N/A'}</Typography>
                                    <Typography>Dispatched at: {typeof selectedOrder.row.dispatched_at !== 'undefined' && selectedOrder.row.dispatched_at !== null ? moment(selectedOrder.row.dispatched_at).format('DD/MM/YYYY hh:mm A') : 'N/A'}</Typography>
                                    <Typography>Delivered at: {typeof selectedOrder.row.delivered_at !== 'undefined' && selectedOrder.row.delivered_at !== null ? moment(selectedOrder.row.delivered_at).format('DD/MM/YYYY hh:mm A') : 'N/A'}</Typography>
                                </Card>
                            </div>
                            <div className="col-12 col-md-6 p-2">
                                <Card variant="outlined" className='p-2'>
                                    <Typography variant='h5'>Delivery Information</Typography>
                                    {
                                        selectedOrder.row.current_status === 'Dispatched' ? <div>
                                            {
                                                selectedOrder.row.transit_status === 'in_transit' ? <Typography>Order is still in transit</Typography> : null
                                            }
                                            {
                                                selectedOrder.row.transit_status === 'returned' ? <div>
                                                    <Typography>Order could not be delivered!</Typography>
                                                    <Typography>Reason: {selectedOrder.row.dispo_message ?? 'Unknown'}</Typography>
                                                </div> : null
                                            }
                                            {
                                                selectedOrder.row.transit_status === 'delivered' ? <div>
                                                    <Typography>Order has been delivered successfully.</Typography>
                                                    <Typography>Delivered at: {moment(selectedOrder.row.delivered_at).format('DD/MM/YYYY hh:mm A')}</Typography>
                                                </div> : null
                                            }
                                        </div> : <Typography>Order not dispatched yet!</Typography>
                                    }

                                </Card>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <Typography variant='h5' className='mb-2'>Order Content</Typography>
                            <DataGrid isRowSelectable={_ => false} rowHeight={80} getRowId={e => e.proid} rows={selectedOrder.row.productList} columns={orderDetailsColumn} autoHeight pageSize={10} />
                        </div>
                        <Divider className='my-4' />
                        {
                            selectedOrder.row.transit_status !== 'Delivered' ? <div className='text-center'>
                                {
                                    {/* actionProgress ? <CircularProgress /> : <div>
                                        {
                                            Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_sent_update_transit_status') ? <Button variant='contained' color='primary' className='mx-2' onClick={_ => openActionDrawer()}>Update Transit Status</Button> : null
                                        }
                                    </div> */}
                                }

                            </div> : null
                        }
                    </div>
                </Dialog> : null
            }
            <Dialog maxWidth={512} open={searchDialogState.open} onClose={closeSearchInterface}>
                <div className='d-flex align-items-center p-2 me-1'>
                    <SearchIcon color='primary' />
                    <InputBase className='mx-2' value={searchDialogState.query} onChange={e => setSearchDialogState(old => ({ ...old, query: e.target.value }))} autoFocus placeholder='Search Order...' fullWidth />
                    <IconButton onClick={closeSearchInterface}><Close color='error' /></IconButton>
                </div>
                <Divider />
                {
                    searchDialogState.isSearching ? <LinearProgress /> : null
                }
                <div style={{ minHeight: 250 }}>
                    <DialogContent>
                        <div className='row'>
                            <div className="col-12 col-md-6 p-2">
                                <Button onClick={_ => searchOrder('orderId')} disabled={searchDialogState.query.trim().length < 5} variant='contained' fullWidth color='primary'>Search by Order Id</Button>
                            </div>
                            <div className="col-12 col-md-6 p-2">
                                <Button onClick={_ => searchOrder('trackingId')} disabled={searchDialogState.query.trim().length < 5} variant='contained' fullWidth color='warning'>Search by Tracking Id</Button>
                            </div>
                            <div className="col-12 col-md-6 p-2">
                                <Button onClick={_ => searchOrder('crmId')} disabled={searchDialogState.query.trim().length < 5} variant='contained' fullWidth color='secondary'>Search by CRM Id</Button>
                            </div>
                            <div className="col-12 col-md-6 p-2">
                                <Button onClick={_ => searchOrder('mobile')} disabled={searchDialogState.query.trim().length < 5} variant='contained' fullWidth color='success'>Search by Phone Number</Button>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogContent>
                        {
                            searchDialogState.error !== '' ? <div className='text-center'>
                                <ErrorOutlineOutlined fontSize='large' color='error' />
                                <Typography color={'error'} className='mt-1'>{searchDialogState.error}</Typography>
                            </div> : null
                        }
                        {
                            searchDialogState.result.length > 0 ? <div>
                                <DataGrid
                                    autoHeight
                                    rowHeight={80}
                                    rows={searchDialogState.result}
                                    onCellClick={(e) => {
                                        if (['payment_status', 'order_source_zone', 'transit_status', 'total_price'].includes(e.field)) {
                                            closeSearchInterface()
                                            setSelectedOrder(e)
                                        }
                                    }}
                                    getRowId={e => e.orderid}
                                    columns={searchColumns}
                                    pageSize={10}
                                />
                            </div> : searchDialogState.executedOnce && !searchDialogState.isSearching ? <Typography>No result found!</Typography> : null
                        }
                        {/* <div className='d-flex flex-column align-items-center mt-5'>
                        <Avatar alt="AR Ayurveda" src="/logo512.png" />
                        <Typography className='mt-2 text-muted'>You can search an order with Order Id, Tracking Id, CRM Id, or Phone Number</Typography>
                        <Typography variant='subtitle2' className='text-muted'>Your search results will appear here.</Typography>
                    </div> */}
                    </DialogContent>
                </div>

            </Dialog>
        </Box>
    )
}
