import { Box, CircularProgress } from '@mui/material';
import Crypto from 'crypto-js';
import * as React from 'react';
import './App.css';
import { Layout } from './Components';
import { ApiHelper } from './Helpers';
import Login from './Pages/Login';
import { Cancelled, Dashboard, Delivered, Hold, Pending, Returned, Sent, Unshipped } from './Tabs';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function App() {
  const apiHelper = React.useMemo(() => new ApiHelper(), [])
  const [renderState, setRenderState] = React.useState({
    render: false,
    processed: false
  })
  const token = localStorage.getItem('order_room_token')
  let permissions = localStorage.getItem('order_room_permissions')
  // console.log(token)
  // console.log(permissions)
  const isAuthenticated = token !== null && permissions !== null
  if (permissions !== null) {
    permissions = Crypto.AES.decrypt(permissions, process.env.REACT_APP_ENCRYPTION_KEY).toString(Crypto.enc.Utf8).split(',')
  } else {
    permissions = []
  }

  // console.log(permissions)
  const [currentTab, setCurrentTab] = React.useState(parseInt(localStorage.getItem('order_room_tab') ?? 0))
  localStorage.removeItem('order_room_tab')
  const pages = []
  if (Array.isArray(permissions)) {
    if (permissions.includes('order_room_v2_dashboard')) {
      pages.push(<Dashboard permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_pending')) {
      pages.push(<Pending permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_unshipped')) {
      pages.push(<Unshipped permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_hold')) {
      pages.push(<Hold permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_cancelled')) {
      pages.push(<Cancelled permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_sent')) {
      pages.push(<Sent permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_delivered')) {
      pages.push(<Delivered permissions={permissions} tIndex={pages.length} />)
    }
    if (permissions.includes('order_room_v2_returned')) {
      pages.push(<Returned permissions={permissions} tIndex={pages.length} />)
    }
  }

  React.useEffect(() => {
    const verifyIp = async () => {
      const response = await apiHelper.verifyIp()
      if (response.ok) {
        setRenderState({
          processed: true,
          render: true
        })
      } else {
        setRenderState({
          processed: true,
          render: false
        })
      }
    }

    verifyIp()
  }, [apiHelper])


  return (
    <div>
      {
        renderState.processed && renderState.render ? isAuthenticated ? <Layout currentTab={currentTab} permissions={permissions} setCurrentTab={setCurrentTab}>
          {
            pages.map((e, index) => <TabPanel key={index} value={currentTab} index={index}>{e}</TabPanel>)
          }
        </Layout> : <Login /> : renderState.processed === false ? <div className='d-flex vh-100 align-items-center justify-content-center'>
          <CircularProgress />
        </div> : null
      }
    </div>
  );
}

export default App;
