import { Alert, Button, CircularProgress, TextField } from '@mui/material'
import React, { useRef, useState } from 'react'
import { ApiHelper } from '../Helpers'

export default function Login() {
    const apiHelper = new ApiHelper()
    const usernameRef = useRef('')
    const passwordRef = useRef('')
    const [errorState, setErrorState] = useState({
        enabled: false,
        message: ''
    })
    const [processing, setProcessing] = useState(false)

    const attemptLogin = async () => {
        const username = usernameRef.current.trim()
        const password = passwordRef.current.trim()

        if (username === '') {
            setErrorState({
                enabled: true,
                message: "Username is required!"
            })
            return
        }

        if (password === '') {
            setErrorState({
                enabled: true,
                message: "Password is required!"
            })
            return
        }

        setErrorState({
            enabled: false,
            message: ""
        })

        setProcessing(true)
        const response = await apiHelper.attemptLogin(username, password)
        setProcessing(false)
        if (response.ok) {
            // console.log(response)
            if (Array.isArray(response.data.data) && response.data.data.length > 0 && typeof response.data.data[0].token !== 'undefined' && typeof response.data.data[0].permissions !== 'undefined') {
                localStorage.setItem('order_room_token', response.data.data[0].token)
                localStorage.setItem('order_room_permissions', response.data.data[0].permissions)
                window.location.reload()
            } else {
                setErrorState({
                    enabled: true,
                    message: 'Unable to authenticate!'
                })
            }
        } else {
            setErrorState({
                enabled: true,
                message: response.message
            })
        }
    }

    return (
        <div className='d-flex align-items-center justify-content-center vh-100'>
            <div style={{
                textAlign: 'center',
                padding: 20,
                maxWidth: 570
            }}>
                <img src='/logo.gif' className='img-fluid' alt="AR Ayurveda" />
                {
                    errorState.enabled ? <Alert severity="error">{errorState.message}</Alert> : null
                }
                <TextField label="Username" onChange={e => usernameRef.current = e.target.value} className='mt-4' variant="outlined" fullWidth />
                <TextField label="Password" type={'password'} onChange={e => passwordRef.current = e.target.value} className='mt-3' variant="outlined" fullWidth />
                <Button disabled={processing} variant='contained' className='mt-3' fullWidth onClick={attemptLogin}>{processing ? <CircularProgress size={25} /> : 'Login'}</Button>
            </div>
        </div>
    )
}
