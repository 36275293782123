import { CancelOutlined, KeyboardReturnOutlined, LocalShippingOutlined, LocationOnOutlined, PendingOutlined, QueryBuilderOutlined, VerifiedOutlined } from '@mui/icons-material'
import { Box, Card, CardContent, Divider, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { ApiHelper } from '../Helpers'

export default function Dashboard() {
  const apiHelper = useMemo(() => new ApiHelper(), [])
  const [dataGridState, setDataGridState] = useState([])
  // const [dataGridState, setDataGridState] = useState({
  //   columns: [],
  //   rows: []
  // })

  useEffect(() => {
    const getData = async () => {
      const response = await apiHelper.getDashboard()
      if (response.ok) {
        setDataGridState(response.data)
        // setDataGridState({
        //   // columns: [
        //   //   { field: 'status', headerName: 'Status', width: 70 },
        //   //   { field: 'count', headerName: 'Count', width: 70 },
        //   // ],
        //   columns: Object.keys(response.data).map(e => ({
        //     field: e, headerName: e, width: 100, renderCell: (cell) => {
        //       return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
        //         <Typography variant='body2' noWrap>{value}</Typography>
        //       </div>} thousandSeparator={true} thousandsGroupStyle='thousand' />
        //     }
        //   })),
        //   rows: [response.data]
        // })
      }
    }
    getData()
  }, [apiHelper])



  return (
    <div>
      <Box>
        {/* <Typography variant='h6'>Today's statistics</Typography> */}
        <div className='row'>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#1976d2', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <PendingOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.new ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>New Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            {/* <Card variant='outlined' className='dashboard_card'>
            <CardContent>
              <div className="d-flex align-items-center">
                <VerifiedOutlined />
                <Typography variant='h5' className='ms-1'>{dataGridState.accepted ?? 'N/A'}</Typography>
              </div>
              <Typography>Accepted Orders</Typography>
            </CardContent>
          </Card> */}
            <Card variant='outlined' style={{ backgroundColor: '#0288d1', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <VerifiedOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.accepted ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Accepted Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#ed6c02', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <QueryBuilderOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.hold ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Hold Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#d32f2f', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <CancelOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.cancelled ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Cancelled Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#33861f', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <LocalShippingOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.dispatched ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Dispatched Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#2a5121', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <LocationOnOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.delivered ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Delivered Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#8d0000', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <KeyboardReturnOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.returned ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Returned Orders</Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </Box>
      {/* <Box className='mt-3'>
        <Typography variant='h6'>Yesterday's statistics</Typography>
        <div className='row'>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#1976d2', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <PendingOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.new ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>New Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#0288d1', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <VerifiedOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.accepted ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Accepted Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#ed6c02', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <QueryBuilderOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.hold ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Hold Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#d32f2f', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <CancelOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.cancelled ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Cancelled Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#33861f', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <LocalShippingOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.dispatched ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Dispatched Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#2a5121', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <LocationOnOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.delivered ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Delivered Orders</Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-12 col-md-3 col-lg-2 p-2'>
            <Card variant='outlined' style={{ backgroundColor: '#8d0000', color: 'white' }}>
              <CardContent className='p-1'>
                <div className='d-flex align-items-center justify-content-center'>
                  <KeyboardReturnOutlined fontSize='large' />
                  <Typography variant='h3' className='ms-2'>{dataGridState.returned ?? 'N/A'}</Typography>
                </div>
                <Divider />
                <Typography className='text-center mt-2'>Returned Orders</Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </Box> */}
    </div>
  )
}
