import { AndroidOutlined, Apple, Close, HelpOutlineOutlined, LanguageOutlined, MailOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, AppBar, Box, Card, Chip, CircularProgress, Dialog, IconButton, Slide, Toolbar, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import TimeAgo from 'react-timeago'
import { ApiHelper } from '../Helpers'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})



export default function Cancelled() {
  const apiHelper = useMemo(() => new ApiHelper(), [])
  const [pageSize, setPageSize] = useState(10)
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [errorState, setErrorState] = useState({
    message: '',
    active: false,
  })
  const [selectedOrder, setSelectedOrder] = useState("")
  const [orderResponseMetaData, setOrderResponseMetaData] = useState()

  const columns = [
    {
      field: 'created_at', headerName: 'Order Date', type: 'date', width: 180, renderCell: (cell) => {
        return <div>
          <Typography variant='subtitle2' fontWeight={'bold'}>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
          <Typography variant='caption'><TimeAgo date={cell.value} /> at {moment(cell.value).format('hh:mm A')}</Typography>
        </div>
      }
    },
    {
      field: 'orderid', headerName: 'Order Detail', width: 150, renderCell: (cell) => {
        return <Tooltip title={`${cell.value} - ${cell.row.full_name}`} >
          <div>
            <Typography noWrap variant="body2" fontWeight={'bold'}>
              {cell.value}
            </Typography>
            <Typography variant='subtitle2' noWrap>
              {cell.row.full_name}
            </Typography>
          </div>
        </Tooltip >
      }
    },
    {
      field: 'crmid', headerName: 'CRM Id', width: 130, renderCell: (cell) => {
        return <Tooltip title={cell.value} >
          <Typography variant='subtitle2' noWrap>
            {cell.value ?? "N/A"}
          </Typography>
        </Tooltip >
      }
    },
    {
      field: 'payment_status', headerName: 'Payment Status', width: 170, renderCell: (cell) => {
        if (cell.value === "Pending") {
          return <Chip color='warning' label='Pending' />
        } else if (cell.value === "Success") {
          return <Chip color='success' label='Success' />
        } else if (cell.value === "Failed") {
          return <Chip color='error' label='Failed' />
        } else {
          return <Chip color='default' label='Unknown' />
        }
      }
    },
    {
      field: 'order_source_zone', headerName: 'Order Source', width: 170, renderCell: (cell) => {
        console.log(cell.value)
        if (cell.value === "Website") {
          return <Chip color='info' label='Website' icon={<LanguageOutlined />} />
        } else if (cell.value === "Android") {
          return <Chip color='success' label='Android' icon={<AndroidOutlined />} />
        } else if (cell.value === "iOS") {
          return <Chip color='secondary' label='iOS' icon={<Apple />} />
        } else {
          return <Chip color='default' label='Unknown' icon={<HelpOutlineOutlined />} />
        }
      }
    },
    {
      field: 'product_name', headerName: 'Product Name', width: 200, renderCell: (cell) => {
        return <Tooltip title={cell.row.productList[0].short_name}>
          <div>
            <Typography noWrap variant="body2">
              {cell.row.productList[0].short_name}
            </Typography>
            {
              cell.row.product_count > 1 ? <Typography noWrap variant="caption">
                with {cell.row.product_count - 1} other
              </Typography> : null
            }
          </div>
        </Tooltip>
      }
    },
    {
      field: 'total_price', headerName: 'Order Amount', width: 170, type: 'number', renderCell: (cell) => {
        return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
          <Chip className='text-capitalize mt-1' color={cell.row.payment_mode === 'online' ? 'success' : 'secondary'} label={`${value} - ${cell.row.payment_mode}`} />
        </div>} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
      }
    },
    {
      field: 'phone', headerName: 'Contact', width: 140, renderCell: (cell) => {
        return <Tooltip title={cell.value}>
          <Typography noWrap variant="body2">
            {cell.value}
          </Typography>
        </Tooltip>
      }
    },
    {
      field: 'state', headerName: 'Destination', width: 170, renderCell: (cell) => {
        return <Tooltip title={`${cell.value} - ${cell.row.pincode}`} >
          <div>
            <Typography noWrap variant="body2" fontWeight={'bold'}>
              {cell.value}
            </Typography>
            <Typography variant='subtitle2' noWrap>
              <MailOutlined fontSize='small' /> {cell.row.pincode}
            </Typography>
          </div>
        </Tooltip >
      }
    },
  ];


  const orderDetailsColumn = [
    {
      field: 'product_image', headerName: 'Image', width: 150, filterable: false, sortable: false, renderCell: (cell) => {
        return <img src={cell.value} alt={cell.row.product_name} className='img-fluid img-thumbnail' style={{
          maxHeight: 60,
          maxWidth: 60
        }} />
      }
    },
    { field: 'product_name', headerName: 'Product Name', flex: 1 },
    {
      field: 'payment_status', headerName: 'Payment Status', width: 170, renderCell: (_) => {
        if (selectedOrder.row.payment_status === "Pending") {
          return <Chip color='warning' label='Pending' />
        } else if (selectedOrder.row.payment_status === "Success") {
          return <Chip color='success' label='Success' />
        } else if (selectedOrder.row.payment_status === "Failed") {
          return <Chip color='error' label='Failed' />
        } else {
          return <Chip color='default' label='Unknown' />
        }
      }
    },
    {
      field: 'item_qty', headerName: 'Item Quantity', width: 180, renderCell: (cell) => {
        return <Chip label={cell.value} color="info" />
      }
    },
    {
      field: 'paid_amount', headerName: 'Paid Amount', width: 180, renderCell: (cell) => {
        const orderData = orders.filter(e => e.orderid === cell.row.orderid).shift();
        return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
          <Chip className='text-capitalize mt-1' color={orderData.payment_mode === 'online' ? 'success' : 'secondary'} label={`${value} - ${orderData.payment_mode}`} />
        </div>} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
      }
    },
  ]

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await apiHelper.fetchCancelled()
      if (response.ok) {
        setOrderResponseMetaData(response.data)
        setOrders(response.data.orders)
        setLoading(false)
      } else {
        setErrorState({
          active: true,
          message: response.message
        })
        setLoading(false)
      }
    }
    getData()
  }, [apiHelper, currentPage, pageSize])


  return (
    <Box>
      {
        loading ? <div className='d-flex justify-content-center mt-5'>
          <CircularProgress />
        </div> : <div className='container-fluid'>
          {
            errorState.active ? <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorState.message}
            </Alert> : <Typography variant='subtitle2' className='mt-2 fw-bold text-danger'>*Click anywhere on the order to view details.</Typography>
          }
          <DataGrid
            className='mt-3'
            rows={orders}
            getRowId={e => e.orderid}
            columns={columns}
            autoHeight
            rowHeight={80}
            paginationMode='server'
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            pageSize={pageSize}
            onPageSizeChange={(e) => setPageSize(e)}
            onPageChange={(e) => {
              setCurrentPage(e)
            }}
            rowCount={orderResponseMetaData.totalData ?? 0}
            page={currentPage}
            // onRowClick={(data) => setSelectedOrder(data)}
            onCellClick={(e) => {
              if (['payment_status', 'order_source_zone', 'order_status', 'total_price'].includes(e.field)) {
                setSelectedOrder(e)
              }
            }}
            error={errorState.message.length > 0 ? errorState.message : null}
          />
          {
            selectedOrder !== "" ? <Dialog
              fullScreen
              open={selectedOrder !== ""}
              // onClose={}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={_ => setSelectedOrder("")}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Order Details
                    <Typography variant="subtitle2" color="#fffc" >Order Id: {selectedOrder.row.orderid} | CRM Id: {selectedOrder.row.crmid ?? 'N/A'}</Typography>
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className="container mt-5">
                <div className="row">
                  <div className="col-12 col-md-6 p-2">
                    <Card variant="outlined" className='p-2'>
                      <Typography variant='h5'>Ship To</Typography>
                      <Typography>{selectedOrder.row.full_name} - (<a className='text-decoration-none' href={`tel:${selectedOrder.row.phone}`}>{selectedOrder.row.phone}</a>)</Typography>
                      <Typography>
                        {
                          `${selectedOrder.row?.address_line_1 ?? ''}, ${selectedOrder?.row.address_line_2 ?? ""}, ${selectedOrder.row?.city ?? ""}, ${selectedOrder.row?.district ?? ""}, ${selectedOrder.row?.state ?? ""} - ${selectedOrder.row?.pincode ?? ""}`
                        }
                      </Typography>
                    </Card>
                  </div>
                  <div className="col-12 col-md-6 p-2">
                    <Card variant="outlined" className='p-2'>
                      <Typography variant='h5'>Order Information</Typography>
                      Order Amount: <NumberFormat displayType='text' value={selectedOrder.row.total_price} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
                      <Typography>Total Items: {selectedOrder.row.productList.length}</Typography>
                      <Typography>Ordered at: {moment(selectedOrder.row.created_at).format('DD/MM/YYYY hh:mm A')}</Typography>
                    </Card>
                  </div>
                  <div className="col-12 col-md-6 p-2">
                    <Card variant="outlined" className='p-2'>
                      <Typography variant='h5'>Order Status: Cancelled</Typography>
                      <Typography>Reason: {selectedOrder.row.dispo_message}</Typography>
                    </Card>
                  </div>
                </div>

                <div className='mt-3'>
                  <Typography variant='h5' className='mb-2'>Order Content</Typography>
                  <DataGrid isRowSelectable={_ => false} rowHeight={80} getRowId={e => e.proid} rows={selectedOrder.row.productList} columns={orderDetailsColumn} autoHeight pageSize={10} />
                  <div className='text-center mt-3'>
                    <Typography color='error' fontWeight={'bold'}>*This is a cancelled Order!</Typography>
                  </div>
                </div>
              </div>
            </Dialog> : null
          }
        </div>
      }
    </Box>
  )
}
