import { AndroidOutlined, Apple, Close, HelpOutlineOutlined, LanguageOutlined, MailOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, AppBar, Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import TimeAgo from 'react-timeago'
import { ApiHelper } from '../Helpers'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

export default function Pending(props) {
  const apiHelper = useMemo(() => new ApiHelper(), [])
  const [pageSize, setPageSize] = useState(10)
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorState, setErrorState] = useState({
    message: '',
    active: false,
  })
  const [inDialogError, setInDialogError] = useState('')
  const [selectedOrder, setSelectedOrder] = useState("")
  const [actionDrawerState, setActionDrawerState] = useState({
    open: false,
    type: 'verify'
  })
  const [actionProgress, setActionProgress] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [orderResponseMetaData, setOrderResponseMetaData] = useState()

  const crmIdRef = useRef('')
  const holdReasonRef = useRef('')
  const cancelReasonRef = useRef('')
  const [paymentModeRef, setPaymentModeRef] = useState('')
  const [paymentStatusRef, setPaymentStatusRef] = useState('')

  // const acceptOrder = async () => {
  //   if (crmIdRef.current.trim() === '') {
  //     setInDialogError('CRM ID is required!')
  //     return
  //   }
  //   if (parseInt(crmIdRef.current) === NaN) {
  //     setInDialogError('CRM ID must be number only!')
  //     return
  //   }
  //   //setInDialogError('')
  //   // console.log('Accepting orders')
  //   setActionDrawerState({ open: false, type: '' })
  //   setActionProgress(true)
  //   const response = await apiHelper.acceptOrder(selectedOrder.row.orderid, crmIdRef.current.trim())
  //   if (response.ok) {
  //     window.location.reload()
  //     setActionProgress(false)
  //   } else {
  //     setActionProgress(false)
  //   }
  // }

  // console.log('Current Page: ', currentPage)

  const columns = [
    {
      field: 'created_at', headerName: 'Order Date', type: 'date', width: 180, renderCell: (cell) => {
        return <div>
          <Typography variant='subtitle2' fontWeight={'bold'}>{moment(cell.value).format('DD-MM-YYYY')}</Typography>
          <Typography variant='caption'><TimeAgo date={cell.value} /> at {moment(cell.value).format('hh:mm A')}</Typography>
        </div>
      }
    },
    {
      field: 'orderid', headerName: 'Order Detail', width: 150, renderCell: (cell) => {
        return <Tooltip title={`${cell.value} - ${cell.row.full_name}`} >
          <div>
            <Typography noWrap variant="body2" fontWeight={'bold'}>
              {cell.value}
            </Typography>
            <Typography variant='subtitle2' noWrap>
              {cell.row.full_name}
            </Typography>
          </div>
        </Tooltip >
      }
    },
    {
      field: 'payment_status', headerName: 'Payment Status', width: 170, renderCell: (cell) => {
        if (cell.value === "Pending") {
          return <Chip color='warning' label='Pending' />
        } else if (cell.value === "Success") {
          return <Chip color='success' label='Success' />
        } else if (cell.value === "Failed") {
          return <Chip color='error' label='Failed' />
        } else {
          return <Chip color='default' label='Unknown' />
        }
      }
    },
    {
      field: 'order_source_zone', headerName: 'Order Source', width: 170, renderCell: (cell) => {
        // console.log(cell.value)
        if (cell.value === "Website") {
          return <Chip color='info' label='Website' icon={<LanguageOutlined />} />
        } else if (cell.value === "Android") {
          return <Chip color='success' label='Android' icon={<AndroidOutlined />} />
        } else if (cell.value === "iOS") {
          return <Chip color='secondary' label='iOS' icon={<Apple />} />
        } else {
          return <Chip color='default' label='Unknown' icon={<HelpOutlineOutlined />} />
        }
      }
    },
    {
      field: 'product_name', headerName: 'Product Name', width: 200, renderCell: (cell) => {
        return <Tooltip title={cell.row.productList[0].short_name}>
          <div>
            <Typography noWrap variant="body2">
              {/* {console.log(cell.row.productList[0].short_name)} */}
              {cell.row.productList[0].short_name}
            </Typography>
            {
              cell.row.product_count > 1 ? <Typography noWrap variant="caption">
                with {cell.row.product_count - 1} other
              </Typography> : null
            }
          </div>
        </Tooltip>
      }
    },
    {
      field: 'order_status', headerName: 'Order Status', width: 120, renderCell: (cell) => {
        if (cell.value === null) {
          return <Chip className='text-capitalize' color='primary' label="Pending" />
        } else if (cell.value === 'hold') {
          return <Chip className='text-capitalize' color='warning' label={cell.value?.toLowerCase()} />
        } else {
          return <Chip className='text-capitalize' color='default' label={cell.value?.toLowerCase()} />
        }
      }
    },
    {
      field: 'total_price', headerName: 'Order Amount', width: 170, type: 'number', renderCell: (cell) => {
        return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
          {/* <Typography variant='body2' noWrap>{value}</Typography> */}
          <Chip className='text-capitalize mt-1' color={cell.row.payment_mode === 'online' ? 'success' : 'secondary'} label={`${value} - ${cell.row.payment_mode}`} />
        </div>} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
      }
    },
    {
      field: 'phone', headerName: 'Contact', width: 140, renderCell: (cell) => {
        return <Tooltip title={cell.value}>
          <Typography noWrap variant="body2">
            {cell.value}
          </Typography>
        </Tooltip>
      }
    },
    {
      field: 'state', headerName: 'Destination', width: 170, renderCell: (cell) => {
        return <Tooltip title={`${cell.value} - ${cell.row.pincode}`} >
          <div>
            <Typography noWrap variant="body2" fontWeight={'bold'}>
              {cell.value}
            </Typography>
            <Typography variant='subtitle2' noWrap>
              <MailOutlined fontSize='small' /> {cell.row.pincode}
            </Typography>
          </div>
        </Tooltip >
      }
    },
    // {
    //   field: '', headerName: 'Quick Action', width: 170, renderCell: (cell) => {
    //     return <div>
    //       <Tooltip title="Cancel Order">
    //         <IconButton>
    //           <CancelOutlined />
    //         </IconButton>
    //       </Tooltip>
    //     </div>
    //   }
    // },
  ];

  const orderDetailsColumn = [
    {
      field: 'product_image', headerName: 'Image', width: 150, filterable: false, sortable: false, renderCell: (cell) => {
        return <img src={cell.value} alt={cell.row.product_name} className='img-fluid img-thumbnail' style={{
          maxHeight: 60,
          maxWidth: 60
        }} />
      }
    },
    { field: 'product_name', headerName: 'Product Name', flex: 1 },
    {
      field: 'payment_status', headerName: 'Payment Status', width: 170, renderCell: (_) => {
        if (selectedOrder.row.payment_status === "Pending") {
          return <Chip color='warning' label='Pending' />
        } else if (selectedOrder.row.payment_status === "Success") {
          return <Chip color='success' label='Success' />
        } else if (selectedOrder.row.payment_status === "Failed") {
          return <Chip color='error' label='Failed' />
        } else {
          return <Chip color='default' label='Unknown' />
        }
      }
    },
    {
      field: 'item_qty', headerName: 'Item Quantity', width: 180, renderCell: (cell) => {
        return <Chip label={cell.value} color="info" />
      }
    },
    {
      field: 'paid_amount', headerName: 'Paid Amount', width: 180, renderCell: (cell) => {
        const orderData = orders.filter(e => e.orderid === cell.row.orderid).shift();
        return <NumberFormat displayType='text' value={cell.value} renderText={(value) => <div>
          <Chip className='text-capitalize mt-1' color={orderData.payment_mode === 'online' ? 'success' : 'secondary'} label={`${value} - ${orderData.payment_mode}`} />
        </div>} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
      }
    },
  ]

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await apiHelper.fetchPending(currentPage + 1, pageSize)
      if (response.ok) {
        setOrderResponseMetaData(response.data)
        setOrders(response.data.orders)
        setLoading(false)
      } else {
        setErrorState({
          active: true,
          message: response.message
        })
        setLoading(false)
      }
    }
    getData()
  }, [apiHelper, currentPage, pageSize])

  const openActionDrawer = (type = 'verify') => {
    // console.log('Opening action drawer')
    setActionDrawerState({ open: true, type })
  }

  const updateOrderStatus = async (status) => {
    let message = ''
    if (status === 'verify') {
      const crmId = crmIdRef.current.trim()
      if (/\D/.test(crmId)) {
        setInDialogError('A valid CRM Id is required!')
        return
      }
    }

    // console.log('Trying to update status')
    if (status === 'hold' && holdReasonRef.current.trim() === '') {
      setInDialogError('Reason for hold is required!')
      return
    }


    if (status === 'cancel' && cancelReasonRef.current.trim() === '') {
      setInDialogError('Reason for cancel is required!')
      return
    }

    if (status === 'edit_payment_status' && (paymentModeRef.trim() === '' || paymentStatusRef.trim() === '')) {
      setInDialogError('Please select valid payment mode and payment status')
      return
    }

    // setInDialogError('')
    setActionDrawerState({ open: false, type: '' })
    setActionProgress(true)

    if (status === 'hold') {
      message = holdReasonRef.current.trim()
    }
    if (status === 'cancel') {
      message = cancelReasonRef.current.trim()
    }

    let response
    if (status === 'edit_payment_status') {
      response = await apiHelper.updateOrderPaymentStatus(selectedOrder.row.orderid, paymentStatusRef, paymentModeRef)
    } else {
      response = await apiHelper.updatePendingOrderStatus(selectedOrder.row.orderid, crmIdRef.current.trim(), status, message)
    }
    if (response.ok) {
      localStorage.setItem('order_room_tab', props.tIndex ?? 0)
      window.location.reload()
    } else {
      setActionProgress(false)
      setInDialogError(response.message)
      setActionDrawerState({
        open: true,
        type: status
      })
    }
    // console.log('Can go ahead')
  }

  return (
    <Box>
      {
        loading ? <div className='d-flex justify-content-center mt-5'>
          <CircularProgress />
        </div> : <div className='container-fluid px-5'>
          {
            errorState.active ? <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorState.message}
            </Alert> : <Typography variant='subtitle2' className='mt-2 fw-bold text-danger'>*Click anywhere on the order to view details.</Typography>
          }
          <DataGrid
            className='mt-3'
            rows={orders}
            getRowId={e => e.orderid}
            columns={columns}
            autoHeight
            rowHeight={80}
            paginationMode='server'
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            pageSize={pageSize}
            onPageSizeChange={size => setPageSize(size)}
            onPageChange={(e) => {
              // console.log(e)
              setCurrentPage(e)
            }}
            rowCount={orderResponseMetaData.totalData ?? 0}
            // components={{
            //   Footer: (props) => {
            //     return <Box padding={2} alignItems='center' justifyContent='end' display="flex">
            //       <Typography>Page: {currentPage + 1}</Typography>
            //       <div className='ms-3'>
            //         <IconButton disabled={!orderResponseMetaData.hasPreviousPage} onClick={_ => setCurrentPage(old => old - 1)}><ArrowBackIos fontSize="small" /></IconButton>
            //         <IconButton disabled={!orderResponseMetaData.hasNextPage} onClick={_ => setCurrentPage(old => old + 1)}><ArrowForwardIos fontSize='small' /></IconButton>
            //       </div>
            //     </Box>
            //   }
            // }}
            page={currentPage}
            onCellClick={(e) => {
              if (['payment_status', 'order_source_zone', 'order_status', 'total_price'].includes(e.field)) {
                setSelectedOrder(e)
              }
            }}
            // checkboxSelection
            // onPageSizeChange={(e) => setPageSize(e)}
            // onRowClick={(data, event, details) => {
            //   console.log(data)
            //   console.log(event)
            //   console.log(details)
            //   setSelectedOrder(data)
            // }}
            // rowsPerPageOptions={[10, 30, 50, 70, 100]}
            error={errorState.message.length > 0 ? errorState.message : null}
          />


          {/* Order edit dialog */}
          {
            selectedOrder !== "" ? <Dialog
              fullScreen
              open={selectedOrder !== ""}
              // onClose={}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={_ => setSelectedOrder("")}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Order Details
                    <Typography variant="subtitle2" color="#fffc" >Order Id: {selectedOrder.row.orderid}</Typography>
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className="container mt-5">
                <div className="row">
                  <div className="col-12 col-md-6 p-2">
                    <Card variant="outlined" className='p-2'>
                      <Typography variant='h5'>Ship To</Typography>
                      <Typography>{selectedOrder.row.full_name} - (<a className='text-decoration-none' href={`tel:${selectedOrder.row.phone}`}>{selectedOrder.row.phone}</a>)</Typography>
                      <Typography>
                        {
                          `${selectedOrder.row?.address_line_1 ?? ''}, ${selectedOrder?.row.address_line_2 ?? ""}, ${selectedOrder.row?.city ?? ""}, ${selectedOrder.row?.district ?? ""}, ${selectedOrder.row?.state ?? ""} - ${selectedOrder.row?.pincode ?? ""}`
                        }
                      </Typography>
                    </Card>
                  </div>
                  <div className="col-12 col-md-6 p-2">
                    <Card variant="outlined" className='p-2'>
                      <Typography variant='h5'>Order Information</Typography>
                      Order Amount: <NumberFormat displayType='text' value={selectedOrder.row.total_price} thousandSeparator={true} thousandsGroupStyle='thousand' prefix='₹' />
                      <Typography>Total Items: {selectedOrder.row.productList.length}</Typography>
                      <Typography>Ordered at: {moment(selectedOrder.row.created_at).format('DD/MM/YYYY hh:mm A')}</Typography>
                    </Card>
                  </div>
                </div>
                <div className='mt-3'>
                  <Typography variant='h5' className='mb-2'>Order Content</Typography>
                  <DataGrid isRowSelectable={_ => false} rowHeight={80} getRowId={e => e.proid} rows={selectedOrder.row.productList} columns={orderDetailsColumn} autoHeight pageSize={10} />
                </div>
                <Divider className='my-4' />
                <div className='text-center'>
                  {
                    actionProgress ? <CircularProgress /> : <div>
                      {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_pending_edit_payment_status') ? <Button variant='contained' color='info' className='mx-2' onClick={_ => {
                          // setPaymentModeRef()
                          // console.log(selectedOrder.row)
                          openActionDrawer('edit_payment_status')
                        }}>Edit Payment Status</Button> : null
                      }
                      {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_pending_accept_order') ? <Button variant='contained' color='success' className='mx-2' onClick={_ => openActionDrawer('verify')}>Accept Order</Button> : null
                      }
                      {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_pending_hold_order') && selectedOrder.row.order_status === null ? <Button variant='contained' color='warning' className='mx-2' onClick={_ => openActionDrawer('hold')}>Hold Order</Button> : null
                      }
                      {
                        Array.isArray(props.permissions) && props.permissions.includes('order_room_v2_pending_cancel_order') ? <Button variant='contained' color='error' className='mx-2' onClick={_ => openActionDrawer('cancel')}>Cancel Order</Button> : null
                      }
                    </div>
                  }

                </div>
              </div>
            </Dialog> : null
          }
          {
            selectedOrder !== '' ? <Dialog open={actionDrawerState.open} onClose={_ => {
              setActionDrawerState({ open: false, type: '' })
              setInDialogError('')
              setPaymentModeRef('')
              setPaymentStatusRef('')
            }}>
              <DialogTitle>Update order status</DialogTitle>
              <DialogContent className='mt-2'>
                {
                  actionDrawerState.type === 'verify' ? <TextField
                    className='mt-2'
                    autoFocus
                    // margin="dense"
                    // id="name"
                    onChange={e => crmIdRef.current = e.target.value}
                    label="CRM Id"
                    fullWidth
                    variant="outlined"
                  /> : null
                }
                {
                  actionDrawerState.type === 'hold' ? <TextField
                    className='mt-2'
                    autoFocus
                    label="Reason for hold"
                    onChange={e => holdReasonRef.current = e.target.value}
                    fullWidth
                    variant="outlined"
                  /> : null
                }
                {
                  actionDrawerState.type === 'cancel' ? <TextField
                    className='mt-2'
                    autoFocus
                    label="Reason for cancel"
                    onChange={e => cancelReasonRef.current = e.target.value}
                    fullWidth
                    variant="outlined"
                  /> : null
                }
                {
                  actionDrawerState.type === 'edit_payment_status' && <div>
                    <FormControl fullWidth className='mt-2'>
                      <InputLabel id="payment_mode_label">Payment Mode</InputLabel>
                      <Select
                        labelId="payment_mode_label"
                        id="payment_mode"
                        label="Payment Mode"
                        value={paymentModeRef}
                        onChange={(e) => setPaymentModeRef(e.target.value)}
                      >
                        <MenuItem value="cod">COD</MenuItem>
                        <MenuItem value="online">Prepaid</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth className='mt-3'>
                      <InputLabel id="payment_status_label">Payment Status</InputLabel>
                      <Select
                        labelId="payment_status_label"
                        id="payment_status"
                        value={paymentStatusRef}
                        label="Payment Status"
                        onChange={(e) => setPaymentStatusRef(e.target.value)}
                      // onChange={handleChange}
                      >
                        <MenuItem value="1">Success</MenuItem>
                        <MenuItem value="-1">Failed</MenuItem>
                        <MenuItem value="0">Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                }
                {
                  inDialogError !== '' ? <Typography color={'error'} className='mt-2'>{inDialogError}</Typography> : null
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={_ => {
                  switch (actionDrawerState.type) {
                    case 'verify':
                      updateOrderStatus('verify')
                      break;
                    case 'hold':
                      updateOrderStatus('hold')
                      break;
                    case 'cancel':
                      updateOrderStatus('cancel');
                      break;
                    case 'edit_payment_status':
                      updateOrderStatus('edit_payment_status');
                      break;
                    default:
                      console.log('Can not do anything here')
                      break;
                  }
                }}>Update</Button>
              </DialogActions>
            </Dialog> : null
          }
        </div>
      }
    </Box>
  )
}
